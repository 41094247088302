import config from '../../config'

//custom services
import UserService from '../../services/user';


export default {
  name: 'AccAu',

  props: [
    'info',
    'enums'
  ],

  methods: {
    toggleMode() {
      this.editMode = !this.editMode;
    },

    modifyHandler() {
      if (!this.editMode) this.toggleMode();
      else this.save();
    },

    save() {
      if (!this.$refs.form.validate()) return;
      if (JSON.stringify(this.au) == JSON.stringify(this.oldAu)) return this.error(config.meta.bcMsg);

      this.saving = true;
      return UserService
        .saveInfo(this.au, this.$store.state.uuid)
        .then(() => { 
          this.toggleMode();
          this.success('About you has been modified successfully.');
          this.saving = false;
        })
        .catch(() => { 
          this.error(config.meta.beMsg); 
          this.saving = false; 
        });
    },

    success(msg) {
      this.showSuccess = msg; 
      setTimeout(() => { this.showSuccess = false; }, 2000);
    },

    error(err) {
      this.showError = err;
      setTimeout(() => { this.showError = false }, 2000);
    }

  },

  data() {

    const auObject = {
      story: this.info.story || '',
      quote: this.info.quote || '',
      worldChange: this.info.worldChange || '',
      uniqueThing: this.info.uniqueThing || '',
      whatCanIOffer: this.info.whatCanIOffer || '',
      musicBooksMovies: this.info.musicBooksMovies || '',
      placesFDT: this.info.placesFDT || '',
      placesToVolunteer: this.info.placesToVolunteer || '',
      whereToEat: this.info.whereToEat || '',
      beCareful: this.info.beCareful || '',
      anythingElse: this.info.anythingElse || '',
      social: this.info.social,

      email: this.info.email,
      mobile: this.info.mobile
    };

    return {
      animate: 'animate__animated animate__fadeIn',
      befMsg: config.meta.befMsg,
      bsaveMsg: config.meta.bsaveMsg,
      placeholder: config.meta.placeholder,
      editMode: false,
      saving: false,
      valid: false,
      showSuccess: false,
      showError: false,

      oldAu: JSON.parse(JSON.stringify(auObject)),
      au: auObject,

      validationRules: {
        basicRule: [
          v => !!v || 'It can not be empty.'
        ]
      }
    }
  }
}