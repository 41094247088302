import AccPi from '../../components/acc-pi/acc-pi.vue';
import AccAr from '../../components/acc-ar/acc-ar.vue';
import AccAu from '../../components/acc-au/acc-au.vue';
import AccHp from '../../components/acc-hp/acc-hp.vue';
import AccPp from '../../components/acc-pp/acc-pp.vue';
import AccSs from '../../components/acc-ss/acc-ss.vue';
import AccV from '../../components/acc-v/acc-v.vue';
import AccPhotos from '../../components/acc-photos/acc-photos.vue';
import AccLanguages from '../../components/acc-languages/acc-languages.vue';

import Loading from '../../components/loading/loading.vue';
import UserService from '../../services/user';

export default {
  name: 'AccountSlug',

  methods: {

    fetchUser() {
      const uuid = this.$store.state.uuid;
      return UserService
        .getUserProfile(uuid, true)
        .then(response => {
          this.info = response.data;
        })
        .catch(err => {
          console.log(err);
        });
    },

    fetchEnums() {
      return UserService
        .getUserEnums(true)
        .then(response => {
          this.enums = response.data;
        })
        .catch(err => {
          console.log(err);
        });
    },

    fetchPaymentPayouts() {
      return UserService
        .getPayoutPaouts(true)
        .then(response => {
          this.pp = response.data;
        })
        .catch(err => {
          console.log(err);
        });
    },

    fetchData() {
      this.loading = true;
      if (this.slug === 'payments-and-payouts') {
        return Promise.all([this.fetchPaymentPayouts()])
          .then(() => this.loading = this.refresh = false)
          .catch(() => this.loading = this.refresh = false);
      }

      return Promise.all([this.fetchUser(), this.fetchEnums()])
        .then(() => this.loading = this.refresh = false)
        .catch(() => this.loading = this.refresh = false);
    }

  },

  created() {
    this.fetchData();
  },

  watch: {
    refresh(val) {
      if (val) this.fetchData();
    }
  },

  data() {

    const slug = this.$route.params.slug;
    let name = '';
    if (slug === 'personal-information') name = 'Personal information';
    else if (slug === 'photos') name = 'Photos';
    else if (slug === 'availability-and-rate') name = 'Availability & rate';
    else if (slug === 'languages') name = 'Languages';
    else if (slug === 'about-you') name = 'About you';
    else if (slug === 'hosting-and-pickup') name = 'Hosting & pick-up'
    else if (slug === 'payments-and-payouts') name = 'Payments & payouts';
    else if (slug === 'account-verification') name = 'Account verification';
    else if (slug === 'settings-and-subscription') name = 'Settings & subscription';

    return {
      name: name,
      slug: slug,
      loading: true,
      refresh: false,
      info: {},
      enums: {},
      pp: []
    }

  },

  components: {
    AccPi,
    AccAr,
    AccAu,
    AccHp,
    AccPp,
    AccSs,
    AccV,
    AccPhotos,
    AccLanguages,
    Loading
  }
}