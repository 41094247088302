export default {
  name: 'AccSs',

  methods: {
    showSubscriptionModal() {
      this.$store.commit('showSubscriptionModal')
    },

    cancelSubscription() {
      console.log('cancel my subscription');
    }
  },

  props: [
    'info',
    'enums'
  ],

  data() {
    return {
      subscribed: true,
      showSuccess: false,
      showError: false,
      saving: false,
      animate: 'animate__animated animate__fadeIn'
    }
  }
}