import config from '../../config'

//custom services
import UserService from '../../services/user';

export default {
  name: 'AccV',

  props: [
    'info',
    'enums'
  ],

  methods: {
    submit() {
      if (!this.$refs.form.validate()) return;
      if (JSON.stringify(this.vr) == JSON.stringify(this.oldVR)) return this.error(config.meta.bcMsg)

      const uuid = this.$store.state.uuid;
      this.saving = true;
      return UserService
        .submitAVRequest(this.vr, uuid)
        .then(res => {
          if (res.data.status === 400) this.error(res.data.msg);
          else this.success('Your verification request has been submitted sucessfully.');
          this.saving = false;
         })
        .catch(() => {
          this.saving = true;
          this.error(config.meta.beMsg);
        })
    },

    error(err) {
      this.showError = err;
      setTimeout(() => { this.showError = false }, 1500);
    },

    success(msg) {
      this.showSuccess = msg;
      this.editMode = false;
      this.vr.status = 'pending';
      setTimeout(() => { 
        this.showSuccess = false;
        window.scrollTo(0,0);
      }, 1500);
    },

  },

  data() {

    const pi = this.info.verification.personalIdentification;
    const bd = this.info.verification.bankDetails;
    const vrObject = {
      status: this.info.verification.status,
      personalIdentification: {
        idType: pi ? pi.idType : '',
        idNumber: pi ? pi.idNumber : ''
      },
      bankDetails: {
        accountName: bd ? bd.accountName : '',
        bankName: bd ? bd.bankName : '',
        accountNumber: bd ? bd.accountNumber : '',
        reAccountNumber: bd ? bd.accountNumber : '',
        ifscCode: bd ? bd.ifscCode : '',
        swiftCode: bd ? bd.swiftCode : ''
      }
    };

    return {
      animate: 'animate__animated animate__fadeIn',
      befMsg: config.meta.befMsg,
      bsaveMsg: config.meta.bsaveMsg,
      placeholder: config.meta.placeholder,
      saving: false,
      valid: false,

      showError: false,
      showSuccess: false,

      editMode: !this.info.verification.status || this.info.verification.status === 'declined',

      oldVR: JSON.parse(JSON.stringify(vrObject)),
      vr: vrObject,

      validationRules: {
        basicRule: [
          v => !!v || ''
        ],
        reAccountNumber: [
          v => !!v || '',
          v => v === this.vr.bankDetails.accountNumber || 'Account number does not match'
        ],
        ifscCode: [
          v => !!v || !!this.vr.bankDetails.swiftCode || 'IFSC code is required (if no SWIFT)'
        ],
        swiftCode: [
          v => !!v || !!this.vr.bankDetails.ifscCode || 'SWIFT code is required (if no IFSC)'
        ]
      }
    }
  }
}