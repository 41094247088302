import config from '../../config'

//custom services
import UserService from '../../services/user';


export default {
  name: 'AccLanguages',

  props: [
    'editMode',
    'info',
    'enums'
  ],

  methods: {
    search() {
      if (this.input) this.languages = this.searchFromAll(this.allLanguages);
      else this.languages = this.allLanguages;
    },

    searchFromAll(array) {
      return array.filter((item) => {
          return (item.name && item.name.toLowerCase().indexOf(this.input.toLowerCase()) > -1);
      });
    },

    save() {
      let infoObject = this.ul;
      const filteredSelected = this.languages.filter(x => x.selected);
      if (!filteredSelected.length) return this.error('Please select at least one language.');
      const newLanguages = filteredSelected.map(x => x.name);

      if (newLanguages.join('') === this.oldLanguages.join('')) return this.error(config.meta.bcMsg);

      infoObject.languages = newLanguages
      this.saving = true;
      return UserService
        .saveInfo(infoObject, this.$store.state.uuid)
        .then(() => { 
          this.success('Your languages have been modified successfully.');
          this.saving = false;
        })
        .catch(() => { this.error(config.meta.beMsg); this.saving = false; });
    },

    success(msg) {
      this.showSuccess = msg; 
      setTimeout(() => { this.showSuccess = false; }, 2000);
    },

    error(msg) {
      this.showError = msg;
      setTimeout(() => { this.showError = false }, 2000);
    }
  },

  data() {

    let languagesEnums = [];
    if (this.enums.languages) {
      this.enums.languages.forEach(x => {
        if (this.info.languages.includes(x)) languagesEnums.push({selected: true, name: x});
        else languagesEnums.push({selected: false, name: x});
      });
    }

    return {
      animate: 'animate__animated animate__fadeIn',
      befMsg: config.meta.befMsg,
      bsaveMsg: config.meta.bsaveMsg,
      validationErr: false,
      showSuccess: false,
      showError: false,
      saving: false,

      oldLanguages: this.info.languages,

      ul: {
        email: this.info.email,
        mobile: this.info.mobile
      },

      input: '',
      languages: languagesEnums,
      allLanguages: languagesEnums
    }
  }
}