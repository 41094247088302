import Vue from 'vue'

import GlobalService from '../../services/global'
import UserService from '../../services/user'

export default {
  name: 'AccPhotos',

  props: [
    'editMode',
    'info',
    'enums'
  ],

  created() {
    const totalEmpty = 8-this.photos.length;
    for (let p = 0; p < totalEmpty; p++) this.photos.push(this.photoItem);
  },

  methods: {
    getPhotoURL(data) {
      return GlobalService.getUserPhoto(data);
    },

    getRef(index) {
      return this.$refs['inputUpload'+index][0];
    },

    handleClick(index, photo) {
      if (!photo.id) this.pickImage(index);
      else photo.sheet = !photo.sheet;
    },

    modifyUserInfo() {
      const photos = this.photos.map(p => p.id).filter(x => !!x);
      this.$store.commit('modifyPhotos', photos);
    },

    setAsDp(index, photo) {
      photo.sheet = false;
      this.photos.splice(index, 1);
      this.photos.splice(0, 0, photo);
      this.modifyUserInfo();
      //call in backend without loading
      const photos = this.photos.map(p => p.id).filter(x => !!x);
      return UserService
        .uploadPhotos(photos, this.$store.state.uuid)
        .then(res => console.log('switched'))
        .catch(err => console.log(err))
    },

    removeImage(index, photo) {
      return UserService
        .removeImage(photo.id)
        .then(res => {
          this.photos.splice(index, 1);
          this.photos.push(this.photoItem);
          this.modifyUserInfo();
          photo.sheet = false;
        })
        .catch(err => {
          console.log(err);
        });
    },

    pickImage(index) {
      this.getRef(index).click();
    },

    pushPhotoObject(index, id, uploading, uploadProgress, sheet) {
      Vue.set(this.photos, index, {uploading: uploading, id: id, uploadProgress: uploadProgress, sheet: sheet});
    },

    invalidImageError() {
      this.showError = true;
      setTimeout(() => { this.showError = false }, 2000);
    },

    isFileImage(file) {
      return file && file['type'].split('/')[0] === 'image';
    },

    fileSelected(index) {
      const file = this.getRef(index).files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      const localURL = URL.createObjectURL(file);
      this.pushPhotoObject(index, localURL, true, 0, false);
      this.upload(file, index, localURL);
    },

    upload(file, index, localURL) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      Vue.axios.defaults.headers.common['X-Request-Pattern'] = 'new'; //for backend ais
      return Vue.axios
        .post(`file`, formData, {
          onUploadProgress: progressEvent => {
            const uploadProgress = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
            this.pushPhotoObject(index, localURL, true, uploadProgress, false);
          }
        })
        .then(res => {
          //removing the added header
          Vue.axios.defaults.headers.common['X-Request-Pattern'] = undefined;
          const uploadedPhoto = res.data._id;
          this.pushPhotoObject(index, uploadedPhoto, false, 0, false);
          const photos = this.photos.map(x => x.id);
          UserService
            .uploadPhotos(photos, this.$store.state.uuid)
            .then(() => {
              this.modifyUserInfo();
            })
            .catch(err => {
              this.pushPhotoObject(index, undefined, false, 0, false);
              console.log('error final uploading', err);
            })
        })
        .catch(err => {
          console.log(err);
        })
    }
  },

  data() {
    let photos = [];
    this.info.photos.forEach(x => {
      photos.push({
        id: x, 
        uploading: false, 
        uploadProgress: 0,
        sheet: false
      });
    })
    return {
      photos: photos,
      showError: false,
      photoItem: {
        uploading: false, 
        id: undefined, 
        progress: 0, 
        sheet: false
      }
    }
  }
}