import moment from 'moment'
import config from '../../config'

//custom services
import UserService from '../../services/user';

//custom components
import CstmCountryPicker from '../cstm-country-picker/cstm-country-picker.vue';


export default {
  name: 'AccAr',

  methods: {
    toggleMode() {
      this.editMode = !this.editMode;
    },

    modifyHandler() {
      if (!this.editMode) this.toggleMode();
      else this.save();
    },

    save() {
      if (!this.$refs.form.validate()) return;
      this.saving = true;
      return UserService
        .saveInfo(this.ar, this.$store.state.uuid)
        .then(() => { 
          this.toggleMode();
          this.success('Your availability and rate have been modified successfully.');
          this.saving = false;
        })
        .catch(() => { this.error(config.meta.beMsg); this.saving = false; });
    },

    success(msg) {
      this.showSuccess = msg; 
      setTimeout(() => { this.showSuccess = false; }, 2000);
    },

    error(err) {
      this.showError = err;
      setTimeout(() => { this.showError = false }, 2000);
    },

    allowedHours: v => {
      if ((v >= 18 && v <= 23) || (v >= 0 && v <= 6)) return v
    },

    allowedMinutes: v => {
      if (v == 0 || v == 15 || v == 30 || v == 45) return v;
    },

    getFromattedTime() {
      const old = this.info.pricing.maxNightoutTime;
      if (old && old.length > 5) {
        this.time = moment(old).format('hh:mm');
      } else if (old) this.time = old;
    }
  },

  created() {
    this.getFromattedTime();
  },

  props: [
    'info',
    'enums'
  ],

  watch: {
    country(val) {
      if (val) {
        this.ar.pricing.currency = {
          name: val.name,
          symbol: val.symbol,
          code: val.code,
          country: val.country
        }
      }
    },

    time(val) {
      if (val) this.info.pricing.maxNightoutTime = val;
    }
  },

  data() {

    return {
      animate: 'animate__animated animate__fadeIn',
      befMsg: config.meta.befMsg,
      bsaveMsg: config.meta.bsaveMsg,
      editMode: false,
      valid: false,
      showSuccess: false,
      showError: false,
      saving: false,
      placeholder: config.meta.placeholder,
      country: this.info.pricing.currency || '',

      time: null,
      timeModal: false,

      minHours: [
        {name: '1 hour', hours: 1},
        {name: '2 hours', hours: 2},
        {name: '3 hours', hours: 3},
        {name: '4 hours', hours: 4},
        {name: '5 hours', hours: 5},
        {name: '6 hours', hours: 6},
        {name: '7 hours', hours: 7},
        {name: '8 hours', hours: 8},
        {name: '9 hours', hours: 9},
        {name: '10 hours', hours: 10},
        {name: '11 hours', hours: 11},
        {name: '12 hours', hours: 12},
      ],

      basicItems: [
        {name: 'Yes', value: true},
        {name: 'No', value: false}
      ],

      ar: {
        localiteStatus: this.info.localiteStatus,
        pricing: this.info.pricing,
        /* with every user update request */
        email: this.info.email,
        mobile: this.info.mobile
      },

      validationRules: {
        rateRules: [
          v => /^\d+$/.test(v) || this.ar.pricing.isFree || 'Must be valid a valid rate',
          v => !!v || this.ar.pricing.isFree || 'Must be valid a valid rate',
          v => v != 0 || v != '0' || this.ar.pricing.isFree || 'Must be valid a valid rate'
        ],
        nightRateRules: [
          v => /^\d+$/.test(v) || this.ar.pricing.isFree || this.ar.pricing.isNightRateSame  || 'Must be valid a valid rate',
          v => !!v || this.ar.pricing.isFree || this.ar.pricing.isNightRateSame || 'Must be valid a valid rate',
          v => v != 0 || v != '0' || this.ar.pricing.isFree || this.ar.pricing.isNightRateSame || 'Must be valid a valid rate'
        ]
     }
    }
  },

  components: {
    CstmCountryPicker
  }

}