import config from '../../config'

//custom services
import UserService from '../../services/user';

export default {
  name: 'AccHp',

  props: [
    'info',
    'enums'
  ],

  methods: {
    toggleMode() {
      this.editMode = !this.editMode;
    },

    modifyHandler() {
      if (!this.editMode) this.toggleMode();
      else this.save();
    },

    save() {
      if (!this.$refs.form.validate()) return;
      if (JSON.stringify(this.hp) == JSON.stringify(this.oldHp)) return this.error(config.meta.bcMsg);

      this.saving = true;
      return UserService
        .saveInfo(this.hp, this.$store.state.uuid)
        .then(() => { 
          this.toggleMode();
          this.success('Your hosting information have been modified successfully.');
          this.saving = false;
        })
        .catch(() => { this.error(config.beMsg); this.saving = false; });
    },

    success(msg) {
      this.showSuccess = msg; 
      setTimeout(() => { this.showSuccess = false; }, 2000);
    },

    error(err) {
      this.showError = err;
      setTimeout(() => { this.showError = false }, 2000);
    }
  },

  data() {
    const hObject = {
      hosting: this.info.hosting,
      
      canPickup: this.info.canPickup,
      hasVehicle: this.info.hasVehicle,
      vehicleType: this.info.vehicleType,

      email: this.info.email,
      mobile: this.info.mobile
    };
    return {
      animate: 'animate__animated animate__fadeIn',
      befMsg: config.meta.befMsg,
      bsaveMsg: config.meta.bsaveMsg,
      editMode: false,
      valid: false,
      showSuccess: false,
      showError: false,
      saving: false,
      placeholder: config.meta.placeholder,

      yesnoItems: [
        {name: 'Yes', value: true},
        {name: 'No', value: false}
      ],

      hp: hObject,
      oldHp: JSON.parse(JSON.stringify(hObject)),

      validationRules: {
        priceRule: [
          v => !!v || 'Enter a valid amount',
          v => v != 0 || 'Enter a valid amount',
        ]
      }
    }
  }

  
}