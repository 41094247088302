import config from '../../config'

//custom services
import UserService from '../../services/user';

export default {
  name: 'AccPp',

  props: [
    'ppInfo',
    'refresh'
  ],

  methods: {
    save() {
      if (!this.$refs.form.validate()) return;
      this.saving = true;
      return UserService
        .addBankAccount(this.pp.ba, true)
        .then(r => { 
          if (r.data.status) {
            this.success('New bank account has been added successfully.'); 
          } else this.error(r.data.msg);
          this.saving = false;
        })
        .catch(() => { this.error(config.meta.beMsg); this.saving = false; });
    },

    error(err) {
      this.showError = err;
      setTimeout(() => { this.showError = false }, 1500);
    },

    success(msg) {
      this.showSuccess = msg;
      setTimeout(() => { 
        this.showSuccess = false; 
        this.bankModal = false; 
        this.$refs.form.reset();
        this.refreshPayouts = true;
        window.scrollTo(0,0);
      }, 1500);
    },

    modifyAccount(bank) {
      this.pp.ba = {
        accountName: bank.accountName,
        bankName: bank.bankName,
        accountNumber: '',
        ifscCode: bank.ifscCode,
        swiftCode: bank.swiftCode,
        isPrimary: bank.isPrimary,
        _id: bank._id
      };
      this.bankModal = this.editMode = true;
    },

    removeAccount(id) {
      this.removeBankModal = true;
      this.toBeRemoved = id;
    },

    yesRemove() {
      this.saving = true;
      return UserService
        .removeBankAccount(this.toBeRemoved, true)
        .then(() => { 
          window.scrollTo(0,0);
          this.saving = false;
          this.refreshPayouts = true;
        })
        .catch(() => { this.error(); this.saving = false; });
    },

    modify() {
      if (!this.$refs.form.validate()) return;
      this.saving = true;
      return UserService
        .modifyBankAccount(this.pp.ba, true)
        .then(r => { 
          if (r.data.status) {
            this.success('Your bank account has been modified successfully.');
          } else this.error(r.data.msg);
          this.saving = false;
        })
        .catch(() => { this.error(); this.saving = false; });
    }
  },

  watch: {
    bankModal(val) {
      if (!val) {
        this.editMode = this.saving = false;
        this.pp.ba = {
          accountName: '',
          bankName: '',
          accountNumber: '',
          ifscCode: '',
          swiftCode: '',
          isPrimary: true
        }
      }
    },

    refreshPayouts(val) {
      this.$emit('update:refresh', val);
    }
  },

  data() {

    let primaryAccount = false;
    let accounts = [];
    if (this.ppInfo.length) {
      primaryAccount = this.ppInfo.find(x => x.isPrimary);
      accounts = this.ppInfo.filter(x => !x.isPrimary);
    }

    return {
      valid: false,
      editMode: false,
      bankModal: false,
      saving: false,
      showError: false,
      showSuccess: false,
      placeholder: config.meta.placeholder,
      refreshPayouts: false,

      removeBankModal: false, 
      toBeRemoved: '',

      pp: {
        ba: {
          accountName: '',
          bankName: '',
          accountNumber: '',
          ifscCode: '',
          swiftCode: '',
          isPrimary: true
        },
        primaryAccount: primaryAccount,
        accounts: accounts
      },

      validationRules: {
        nameRules: [
          v => !!v || ''
        ],
        bankRules: [
          v => !!v || ''
        ],
        accountNumberRules: [
          v => !!v || ''
        ],
        reAccountNumberRules: [
          v => !!v || '',
          v => v === this.pp.ba.accountNumber || 'Account number does not match'
        ],
        ifscRules: [
          v => !!v || !!this.pp.ba.swiftCode || 'IFSC code is required (if no SWIFT)'
        ],
        swiftRules: [
          v => !!v || !!this.pp.ba.ifscCode || 'SWIFT code is required (if no IFSC)'
        ]
      }
    }
  }
}