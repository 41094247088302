import moment from 'moment'
import config from '../../config'

//custom services
import UserService from '../../services/user';

//custom components
import CstmCountryPicker from '../cstm-country-picker/cstm-country-picker.vue';
import CstmLocationPicker from '../cstm-location-picker/cstm-location-picker.vue';

export default {
  name: 'AccPi',

  computed: {
    computedDateFormatted () {
      return this.pi.dob ? moment(this.pi.dob).format('dddd, MMMM Do YYYY') : ''
    }
  },

  methods: {
    toggleMode() {
      this.editMode = !this.editMode;
    },

    modifyHandler() {
      if (!this.editMode) this.toggleMode();
      else this.save();
    },

    save() {
      if (!this.$refs.form.validate()) return;
      if (JSON.stringify(this.pi) == JSON.stringify(this.oldPi)) return this.error(config.meta.bcMsg);
      
      this.saving = true;
      return UserService
        .saveInfo(this.pi, this.$store.state.uuid)
        .then(resp => { 
          this.toggleMode();
          this.success('Your personal information has been modified successfully.');
          if (resp.data) {
            this.$store.commit('modifyBasicInfo', {
              name: resp.data.name,
              email: resp.data.email,
              mobile: resp.data.mobile
            });
          }
          this.saving = false;
        })
        .catch(error => { 

          const response = error.response;
          if (response) {
            if (response.data.action === 'phone') {
              this.invalidMobile = true
              this.$refs.form.validate()
            }
            else if (response.data.action === 'email') {
              this.invalidEmail = true;
              this.$refs.form.validate()
            } else this.error(config.meta.beMsg);
          } else {
            this.error(config.meta.beMsg); 
          } 
          
          this.saving = false; 
        })
    },

    success(msg) {
      this.showSuccess = msg; 
      setTimeout(() => { this.showSuccess = false; }, 2000);
    },

    error(err) {
      this.showError = err;
      setTimeout(() => { this.showError = false }, 2000);
    },

    onEmailChange() {
      if (this.invalidEmail) this.invalidEmail = false;
    },

    onMobileChange() {
      if (this.invalidMobile) this.invalidMobile = false;
    }
  },

  props: [
    'info',
    'enums'
  ],

  watch: {
    mobileCountry(val) {
      if (val) {
        this.pi.country = val.name;
        this.pi.callingCode = val.callingCodes[0];
      }
    },

    emergencyCountry(val) {
      if (val) {
        this.pi.emergencyCountry = val.name;
        this.pi.emergencyCallingCode = val.callingCodes[0];
      }
    },

    location(val) {
      if (val) {
        this.pi.location.placeId = val.placeId;
        this.pi.location.description = val.description;
      }
    }
  },

  data() {

    let location = {
      placeId: this.info.location ? this.info.location.placeId : '',
      description: this.info.location ? this.info.location.description : ''
    }

    const piObject = {
      name: this.info.name,
      email: this.info.email || '',
      callingCode: this.info.callingCode || '',
      country: this.info.country || '',
      mobile: this.info.mobile || '',
      emergencyCallingCode: this.info.emergencyCallingCode || '',
      emergencyCountry: this.info.emergencyCountry || '',
      alternate: this.info.alternate || '',
      gender: this.info.gender || '',
      dob: this.info.dob ? new Date(this.info.dob).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10),
      location: location,
      occupation: this.info.occupation,
    };

    return {
      animate: 'animate__animated animate__fadeIn',
      befMsg: config.meta.befMsg,
      bsaveMsg: config.meta.bsaveMsg,
      editMode: false,
      location: location,
      mobileCountry: this.info.country,
      emergencyCountry: this.info.emergencyCountry,
      valid: false,
      dobModal: false,
      showSuccess: false,
      showError: false,
      saving: false,
      invalidMobile: false,
      invalidEmail: false,
      placeholder: config.meta.placeholder,

      oldPi: JSON.parse(JSON.stringify(piObject)),
      pi: piObject,
      validationRules: {
        nameRules: [
          v => !!v || 'Name is required',
          v => v.length <= 30 || 'Name must be less than 30 characters',
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
          v => !this.invalidEmail || config.meta.beeMsg
        ],
        mobileRules: [
          v => !!v || 'Mobile number is required',
          v => /^\d+$/.test(v) || 'Mobile number must be valid',
          v => !this.invalidMobile || config.meta.bmneMsg
        ],
        genderRules: [
          v => !!v || 'Gender is required'
        ],
        dobRules: [
          v => !!v || 'Date of birth is required'
        ],
        occupationRules: [
          v => !!v || 'Occupation is required'
        ]
     }
    }
  },

  components: {
    CstmCountryPicker,
    CstmLocationPicker
  }
}